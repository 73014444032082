import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { ApiService } from '@core/services/api.service';
import { Observable, timer } from 'rxjs';
import {
    catchError,
    delay,
    delayWhen,
    retryWhen,
    switchMap,
    take,
    tap
} from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../../environments/environment';

export const RECONNECT_INTERVAL = environment.reconnectInterval;

@Injectable({
    providedIn: 'root',
})
export class WebSocketService {
    public socket;
    private numOfRetries = 0;

    defaultRetryTimeout = 10;

    private lastMessageTime: number;

    constructor(private apiService: ApiService, private appService: AppService, private router: Router) {
        // this.connect();
        this.lastMessageTime = Date.now();
    }

    /**
     * Creates a new WebSocket subject and send it to the messages subject
     * @param cfg if true the observable will be retried.
     */
    public connect(host_url, host_name?) {
        let socket;
        if (!socket) {
            let url = host_url.includes('https://')
                ? host_url.split('https://')[1]
                : host_url.split('http://')[1];
            if (url.includes('/')) {
                url = url.slice(0, -1);
            }
            socket = webSocket({
                url: host_url.includes('https://')
                    ? `wss://${url}:8000`
                    : `ws://${url}:8000`,
                openObserver: {
                    next: () => {
                        // console.log('[WebSocketService]: connection ok');
                    },
                },
                closeObserver: {
                    next: () => {
                        console.log('[WebSocketService]: connection closed');
                        
                    },
                },
            });
            socket
                .pipe(
                    (o) => o,
                    tap({
                        error: (error) => {
                            console.log(`Websocket Connection issue with ${host_name ? host_name : host_url}`); 
                        },
                    }),
                    
                    retryWhen((errors) =>
                        errors.pipe(
                            tap((val) =>
                                console.log('[WebSocketService] Try to reconnect', val)
                            ),
                            take(3),
                            delay(2000),
                        )
                    ),

                    switchMap((message) => {
                        // console.log(message);
                        const now = Date.now();
                        const diffInSeconds = (now - this.lastMessageTime) / 1000;
                        
                        // console.log(diffInSeconds);
                        // console.log(this.defaultRetryTimeout);
                        if (diffInSeconds >= this.defaultRetryTimeout) {
                            // alert('network slow: ' + diffInSeconds);
                            
                            if(this.router.url.includes('viewer')) {
                                const videos = window["videojs"].getAllPlayers();
                                if(videos.length > 0) {
                                    this.appService.openSnackBar('Unstable or Slow network connection detected. It may affect the performance of viewer page', '', 2000, 'right', 'bottom');
                                    // this.defaultRetryTimeout+2;
                                    this.lastMessageTime = Date.now();
                                }
                            }
                            
                        } else {
                            this.lastMessageTime = Date.now();
                        }
                        return timer(3000);
                      }),
                      catchError((error) => {
                        console.error('WebSocket error:', error);
                        return timer(3000);
                      })
                    // catchError((_) => EMPTY)
                )
                .subscribe(() => {
                    
                  });
            // console.log(socket);
            //toDO only next an observable if a new subscription was made double-check this
            // this.messagesSubject.next(messages);
        }
        socket['host_name'] = host_name ? host_name : '';
        return socket;
    }

    /**
     * Retry a given observable by a time span
     * @param observable the observable to be retried
     */
    private reconnect(observable: Observable<any>): Observable<any> {
        return observable.pipe(
            retryWhen((errors) =>
                errors.pipe(
                    tap((val) =>
                        console.log('[WebSocketService] Try to reconnect', val)
                    ),
                    delayWhen((_) => timer(RECONNECT_INTERVAL)),
                )
            )
        );
    }

    close() {
        // this.socket.complete();
    }

    sendMessage(msg: any) {
        // this.socket.next(msg);
    }
}
