//  It is to make http calls from any place of application
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root', // Injects into root application , no need to import in app module
})
export class ApiService {
    apiDomain = environment.production
        ? window.location.origin
        : `${environment.api_domain}`; // App Domain

    constructor(private http: HttpClient) {}

    apiRequest(
        method: string, // Method of request
        endpoint: string, // End point. Ex('/login')
        body?: any,
        isDomainNeeded?: boolean,
        params?: any
    ): Observable<any> {
        let url = `${this.apiDomain}/mmp-api${endpoint}`;
        url = isDomainNeeded ? endpoint : url;
        // console.log(url);
        let availparams = new HttpParams();
        for (let key in params) {
            availparams = availparams.append(key, params[key]);
        }
        return this.http
            .request(method, url, {
                body,
                params: availparams ? availparams : {},
            })
            .pipe(
                catchError((err) => {
                    // console.log('Error occurred', err);
                    return throwError(err);
                })
            );
    }
    localrequest(method: string, url: string): Observable<any> {
        return this.http.request(method, url).pipe(
            catchError((err) => {
                // console.log('Error occurred', err);
                return throwError(err);
            })
        );
    }
}
