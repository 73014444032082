import { Injectable } from '@angular/core';
import { getTimeZone } from '../functions/common-function';

@Injectable({
    providedIn: 'root'
})
export class AppStateService {

    private timezone = '';
    constructor( ) { }

    getTimezone(): string {
        return getTimeZone(this.timezone);
    }
    setTimezone(timezone): void {
        this.timezone = timezone;
    }
}
