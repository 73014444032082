import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ClipsService {
    clipsSubject = new BehaviorSubject<any>({});
    clipsObservable = this.clipsSubject.asObservable();

    constructor(private apiSrvc: ApiService) {}

    clipsCrudAPI(crudType, APIname, data, params): Observable<any> {
        switch (crudType) {
            case 'List':
                return this.apiSrvc.apiRequest(
                    'GET',
                    `/${APIname}`,
                    {},
                    false,
                    params
                );
                break;
            case 'ListById':
                return this.apiSrvc.apiRequest(
                    'GET',
                    `/${APIname}/${data.id}`,
                    {},
                    false,
                    params
                );
                break;
            case 'Add':
                return this.apiSrvc.apiRequest(
                    'POST',
                    `/${APIname}`,
                    data,
                    false,
                    params
                );
                break;
            case 'Delete':
                return this.apiSrvc.apiRequest(
                    'DELETE',
                    `/${APIname}/${data.id}`,
                    {},
                    false,
                    params
                );
                break;
            case 'Edit':
                let id = '';
                if (APIname === 'channels') {
                    id = params.id;
                    delete params.id;
                }
                return this.apiSrvc.apiRequest(
                    id ? 'POST' : 'PUT',
                    `/${APIname}/${data.id ? data.id : id}`,
                    data,
                    false,
                    params
                );
                break;
        }
    }

    getMediaOption(hostname) {
        return this.apiSrvc.apiRequest(
            'GET',
            `/send-media?host_name=${hostname}`,
            {},
            false
        );
    }
    postMedia(postObj) {
        return this.apiSrvc.apiRequest(
            'POST',
            `/send-media`,
            postObj,
            false
        );
    }

    private sub$ = new BehaviorSubject<any>({}) 
    selectedSub = this.sub$.asObservable();

    extraParams(path) {
        this.sub$.next(path);
    } 
}
