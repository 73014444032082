import { Injectable } from '@angular/core';
import {
    Route,
    Router,
    CanActivate,
    CanLoad,
} from '@angular/router';
import {
    retrieveUserLogin,
    retrieveDefaultRoute,
    getPermissionsList,
} from '@shared/functions/common-function';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(public router: Router) {}

    canActivate(): boolean {
        if (retrieveUserLogin()) {
            this.router.navigate([`/${retrieveDefaultRoute()}`]);
            return false;
        }
        return true;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AuthGuardLoadService implements CanLoad {
    constructor(private router: Router) {}

    canLoad(route: Route): boolean {
        return this._validate(route);
    }

    canActivate(route: Route): boolean {
        return this._validate(route);
    }

    _validate(route): boolean {
        if (!retrieveUserLogin()) {
            this.router.navigate(['/']);
            return false;
        } else if (
            route.data.permission &&
            !this._hasPermission(route.data.permission)
        ) {
            this.router.navigate(['/nopermission']);
            return false;
        }
        return true;
    }

    _hasPermission(permission: string): boolean {
        const userPermissions = getPermissionsList();
        return userPermissions.some(userPermission => permission.includes(userPermission));
    }
}
