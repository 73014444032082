import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { EditorService } from './feature/editor/editor.service';

@Injectable({
    providedIn: 'root',
})
export class AppResolverService {
    constructor(private appSrvc: AppService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let availableEntityData = JSON.parse(
            JSON.stringify(
                this.appSrvc.retrieveEntityData('settingsLeftMenuList')
            )
        );
        return availableEntityData?.data?.loaded
            ? availableEntityData.data
            : this.appSrvc.fetchSettingMenuList();
    }
}

@Injectable()
export class EditorResolverService {
    constructor(
        private appSrvc: AppService,
        private editorSrvc: EditorService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        // console.log(route);
        const rec_id = route?.queryParams?.rec_id;
        const host_name = route?.queryParams.host_name;
        return this.editorSrvc.getRecordingDetails(rec_id, host_name);
    }
}
