import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { retrieveHostName } from '@app/shared/functions/common-function';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { publishReplay, refCount, shareReplay, take } from 'rxjs/operators';

@Injectable()
export class EditorService {
    editorSubject = new Subject<any>();
    editorObservable = this.editorSubject.asObservable();
    currentRecData;
    prevAndNextHourData = [];
    clipStEndJson = {};
    host_name = '';
    constructor(private apiSrvc: ApiService, private route: ActivatedRoute) {
        this.route.queryParamMap.subscribe((params) => {
            this.host_name = params.get('host_name');

        });
    }

    set prevAndNextHourRecordingsData(data) {
        this.prevAndNextHourData = [...data];
    }
    get prevAndNextHourRecordingsData(): Array<any> {
        return this.prevAndNextHourData;
    }

    set clipDataStore(data) {
        this.clipStEndJson = { ...data };
    }
    get clipDataStore(): any {
        return this.clipStEndJson;
    }
    // get Recording Details
    getRecordingDetails(rec_id, host_name) {
        return this.apiSrvc
            .apiRequest(
                'GET',
                `/recordings/${rec_id}?host_name=${host_name}`,
                {},
                false,
                {}
            )
            .toPromise();
    }
    // get Recording Details for next and previous
    getRecordingsForPrevNext(params): Promise<any> {
        return this.apiSrvc
            .apiRequest('GET', `/recordings`, {}, false, params)
            .toPromise();
    }
    // Frames API
    getSubLevelFrames(level, params) {
        switch (level) {
            case 1:
                return this.apiSrvc
                    .apiRequest('GET', '/frames', {}, false, params)
                    .pipe(shareReplay({ bufferSize: 1, refCount: true }))
                    .toPromise();
            case 2:
                return this.apiSrvc
                    .apiRequest('GET', '/frames', {}, false, params)
                    .toPromise();
        }
    }

    // Get resolutions list
    getResolutionsList() {
        return this.apiSrvc
            .apiRequest('GET', '/hq-image-resolutions', {}, false, {})
            .toPromise();
    }

    getClipsUploadingProgress(clipRecId, hostname): Observable<any> {
        return this.apiSrvc.apiRequest(
            'GET',
            `/clips/${clipRecId}?hostname=${hostname}`,
            {},
            false,
            {}
        );
    }

    // Bookmark or cutclip API invoking
    createBookmarkOrCutclip(typeOfExport, exportData, params) {
        switch (typeOfExport) {
            case 'bookmark':
                return this.apiSrvc.apiRequest(
                    'POST',
                    '/bookmarks',
                    exportData,
                    false,
                    params
                );
            case 'cutclip':
                return this.apiSrvc.apiRequest(
                    'POST',
                    '/clips',
                    exportData,
                    false,
                    params
                );
        }
    }

    getMetadata(params): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/metadata', {}, false, {
            host_name: retrieveHostName(),
            ...params,
        });
    }

    getClipsInEditor(params): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/clips', {}, false, params);
    }
    getFramesInAnnotation(annType, recId, params): any {
        switch (annType) {
            case 'frame':
                return this.apiSrvc
                    .apiRequest('GET', `/frames/${recId}`, {}, false, params)
                    .toPromise();
                break;
            case 'List':
                return this.apiSrvc.apiRequest(
                    'GET',
                    `/annotations`,
                    {},
                    false,
                    params
                );
                break;
            case 'Edit':
                return this.apiSrvc.apiRequest(
                    'PUT',
                    `/annotations/${recId}`,
                    {},
                    false,
                    params
                );
                break;
            case 'Add':
                return this.apiSrvc.apiRequest(
                    'POST',
                    `/annotations`,
                    {},
                    false,
                    params
                );
                break;
            case 'Delete':
                return this.apiSrvc.apiRequest(
                    'DELETE',
                    `/annotations/${recId}`,
                    {},
                    false,
                    params
                );
                break;
        }
    }
    getEncodingsList(params): Observable<any> {
        return this.apiSrvc.apiRequest(
            'GET',
            '/encoding-profiles',
            {},
            false,
            params
        );
    }

    setcurrentRecordingData(recordingData): void {
        this.currentRecData = recordingData;
    }
    getcurrentRecordingData(): any {
        if (this.currentRecData !== undefined) {
            return this.currentRecData;
        } else {
            this.currentRecData = JSON.parse(localStorage.getItem('rec_data'));
            return this.currentRecData;
        }
    }

    getEditorTabsList(): Promise<any> {
        return this.apiSrvc
            .apiRequest('GET', `/app-settings`, {}, false, {
                category: 'editor_tabs',
                host_name: retrieveHostName(),
            })
            .toPromise();
    }

    getNSFList(params): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/nsf', {}, false, params);
    }

    getFTPList(params): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/ftp-export', {}, false, params);
    }

    createTags(exportData): Observable<any> {
        return this.apiSrvc.apiRequest(
            'POST',
            '/recordings',
            exportData,
            false
        );
    }

    getburnProfileList(host_name = this.host_name): any {
        return this.apiSrvc.apiRequest('GET', '/burn-profiles', {}, false, {host_name});
    }

    sendMail(sendData): Observable<any> {
        return this.apiSrvc.apiRequest(
            'POST',
            '/mail',
            sendData,
            false
        );
    }
    getS3List(params): Observable<any> {
        return this.apiSrvc.apiRequest('GET', '/s3-settings', {}, false, params);
    }

    // uncommentable code for la7 player issue
    // private passPlayPauseState = new BehaviorSubject('');
    // passPlayPauseStateAsObservable = this.passPlayPauseState.asObservable();

    // updateState(state) {
    //     this.passPlayPauseState.next(state);
    // }

}
