import { Injectable, Injector } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { deleteUserLogin, getBearerToken } from '@shared/functions/common-function';
import { catchError, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
    constructor(
        private httpCancelService: HttpCancelService,
        private router: Router
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const headersConfig = {
            // 'Content-Type': 'application/json',
            // Accept: 'application/json',
        };

        const token = getBearerToken();

        if (token) {
            headersConfig['Authorization'] = `Bearer ${token}`;
        }

        const request = req.clone({ setHeaders: headersConfig });
        return next
            .handle(request)
            .pipe(
                takeUntil(this.httpCancelService.onCancelPendingRequests()),
                catchError(
                    err => {
                        if (err?.status === 401) {
                            this.httpCancelService.cancelPendingRequests();
                            deleteUserLogin();
                            this.router.navigate(['/']);
                        }
                        return throwError(err);
                    }
                )
            );
    }
}

// httpcancel.service.ts
@Injectable({
    providedIn: 'root',
})
export class HttpCancelService {
    private pendingHTTPRequests$ = new Subject<void>();

    constructor() { }

    // Cancel Pending HTTP calls
    public cancelPendingRequests(): void {
        this.pendingHTTPRequests$.next();
    }

    public onCancelPendingRequests() {
        return this.pendingHTTPRequests$.asObservable();
    }
}
